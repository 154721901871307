import { render, staticRenderFns } from "./AppDetails.vue?vue&type=template&id=f0b591ae&scoped=true&"
import script from "./AppDetails.vue?vue&type=script&lang=js&"
export * from "./AppDetails.vue?vue&type=script&lang=js&"
import style0 from "./AppDetails.vue?vue&type=style&index=0&id=f0b591ae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0b591ae",
  null
  
)

export default component.exports